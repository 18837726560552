import React, { useEffect } from "react";
import { KeyIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";
import { Button, ButtonVariant } from "componentsV2/Button";
import { useSnackbar } from "modulesV2/Snackbar";
import { sendTenantGateCode } from "api/tenants";
import { useConversationModal } from "modulesV2/ConversationModal/contexts/ConversationModalContext";

export type SendGateCodeButtonProps = {
  tenantId: number;
};

const DEFAULT_ERROR_MESSAGE = "Unable to send gate access code";

export const SendGateCodeButton: React.FC<SendGateCodeButtonProps> = ({
  tenantId,
  ...buttonProps
}) => {
  const { isSendingGateCode, setIsSendingGateCode } = useConversationModal();
  const { showErrorSnack } = useSnackbar();

  useEffect(() => {
    if (isSendingGateCode) {
      const timer = setTimeout(() => {
        setIsSendingGateCode(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isSendingGateCode]);

  const label = "Send Facility Access Information";

  const sendGateAccessCode = async () => {
    try {
      setIsSendingGateCode(true);
      await sendTenantGateCode(tenantId);
    } catch (e) {
      showErrorSnack(e instanceof Error ? e.message : DEFAULT_ERROR_MESSAGE);
      setIsSendingGateCode(false);
    }
  };

  return (
    <Tooltip title={label} placement="top">
      <span>
        <Button
          type="button"
          onClick={sendGateAccessCode}
          Icon={KeyIcon}
          variant={ButtonVariant.WHITE}
          loading={isSendingGateCode}
          {...buttonProps}
        />
      </span>
    </Tooltip>
  );
};

type SendGateCodeButtonDisabledProps = {
  message: string;
};

export const SendGateCodeButtonDisabled: React.FC<
  SendGateCodeButtonDisabledProps
> = ({ message }) => {
  return (
    <Tooltip title={message} placement="top">
      <span>
        <Button
          type="button"
          disabled
          Icon={KeyIcon}
          variant={ButtonVariant.WHITE}
        />
      </span>
    </Tooltip>
  );
};
