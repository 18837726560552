import client from "./client";
import {
  CommsEvent,
  Paginated,
  Tenant,
  TenantDocumentsResponse,
  TenantLedger,
} from "./types";

/**
 * Search for tenants with provided phone number.
 * @param phoneNumber tenant phone number
 * @returns Tenant array
 */
export const searchTenants = (phoneNumber: string) =>
  client.get<Paginated<Tenant>>("/v1/organizations/current/tenants", {
    params: {
      phone_numbers__phone_number: phoneNumber,
    },
  });

/**
 * Returns CommsEvents within optionally provided range
 * @param tenantId
 * @param params.start start date ISO string (inclusive)
 * @param params.end end date ISO string (exclusive)
 * @returns paginated CommsEvents
 */
export const getTenantCommsEvents = (
  tenantId: number,
  params: Partial<{ start: string; end: string; limit: number }> = {}
) => {
  return client.get<Paginated<CommsEvent>>(
    `/v1/tenants/${tenantId}/comms-events`,
    {
      params: {
        limit: params.limit ?? 100,
        time_created__gte: params.start,
        time_created__lt: params.end,
      },
    }
  );
};

export const getFacilityTenantLedgers = async (
  facilityId: number,
  tenantId: number
) =>
  client.get<TenantLedger[]>(
    `/v1/facilities/${facilityId}/tenants/${tenantId}/ledgers`
  );

export const getFacilityTenantDocuments = async (
  facilityId: number,
  tenantId: number
) =>
  client.get<TenantDocumentsResponse>(
    `/v1/facilities/${facilityId}/tenants/${tenantId}/documents`
  );

export const sendTenantGateCode = (tenantId: number) =>
  client.post(`/v1/tenants/${tenantId}/send-gate-code`);
