import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Conversation } from "api/types";
import { useTwilioConversations } from "contexts/TwilioConversations";
import { useSnackbar } from "modulesV2/Snackbar";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";
export interface ConversationModalContextValue {
  active: boolean;
  conversations: Conversation[];
  handleClose: () => void;
  handleFacilityClick?: () => void;
  isDialedIn: boolean;
  handleDialIn: () => Promise<void>;
  dialInDisabled?: boolean;
  handleHangUp: () => Promise<void>;
  hangUpDisabled?: boolean;
  isClosingModal: boolean;
  messageInputValue: string;
  setMessageInputValue: React.Dispatch<React.SetStateAction<string>>;
  isSendingGateCode: boolean;
  setIsSendingGateCode: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialValue: ConversationModalContextValue = {
  active: false,
  conversations: [],
  handleClose: emptyFn,
  isDialedIn: false,
  handleDialIn: asyncEmptyFn,
  handleHangUp: asyncEmptyFn,
  isClosingModal: false,
  messageInputValue: "",
  setMessageInputValue: emptyFn,
  isSendingGateCode: false,
  setIsSendingGateCode: emptyFn,
};

const ConversationModalContext =
  createContext<ConversationModalContextValue>(initialValue);

export interface ConversationModalProviderProps {
  children: React.ReactNode;
  conversations: Conversation[];
  active: boolean;
  handleCloseModal: () => void;
  handleFacilityClick?: () => void;
  isDialedIn: boolean;
  handleDialIn: () => Promise<void>;
  dialInDisabled?: boolean;
  handleHangUp: () => Promise<void>;
  hangUpDisabled?: boolean;
}

export const ConversationModalProvider: React.FC<
  ConversationModalProviderProps
> = ({
  children,
  conversations,
  active,
  handleCloseModal,
  handleFacilityClick,
  isDialedIn,
  handleDialIn,
  dialInDisabled,
  handleHangUp,
  hangUpDisabled,
}) => {
  const [isClosingModal, setIsClosingModal] = useState(false);
  const { showErrorSnack } = useSnackbar();
  const { getConversationBySid } = useTwilioConversations();
  const [messageInputValue, setMessageInputValue] = useState<string>("");
  const [isSendingGateCode, setIsSendingGateCode] = useState(false);

  const twilioConversations = useMemo(() => {
    return conversations
      .map((conversation) =>
        getConversationBySid(conversation.twilioConversationSid)
      )
      .filter((conversation) => conversation !== undefined); // Filter out undefined values
  }, [conversations, getConversationBySid]);

  const handleClose = useCallback(async () => {
    try {
      if (twilioConversations.length > 0) {
        // Twilio conversation found, leave before closing modal.
        setIsClosingModal(true);
        await Promise.all(
          twilioConversations.map((twilioConversation) => {
            if (twilioConversation) {
              return twilioConversation.leave();
            }
          })
        );
      }
      handleCloseModal();
    } catch {
      showErrorSnack("Unable to leave conversation");
    } finally {
      setIsClosingModal(false);
    }
  }, [handleCloseModal, twilioConversations]);

  return (
    <ConversationModalContext.Provider
      value={{
        active,
        isClosingModal,
        conversations,
        handleClose,
        handleFacilityClick,
        isDialedIn,
        handleDialIn,
        dialInDisabled,
        handleHangUp,
        hangUpDisabled,
        messageInputValue,
        setMessageInputValue,
        isSendingGateCode,
        setIsSendingGateCode,
      }}
    >
      {children}
    </ConversationModalContext.Provider>
  );
};

export const useConversationModal = () => useContext(ConversationModalContext);
export const MockConversationModalProvider = createMockContextProvider(
  ConversationModalContext,
  initialValue
);
