import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AxiosError } from "axios";

import { getTenantCommsEvents } from "api/tenants";
import { CommsEvent, CommsEventType, Conversation } from "api/types";
import { useTwilioConversations } from "contexts/TwilioConversations";
import { useDispatch, useSelector } from "hooks/redux";
import { useSnackbar } from "modulesV2/Snackbar";
import {
  conversationDraftSelector,
  setConversationDraft,
} from "state/dashboardSlice";
import { getConversationCustomerDisplayName } from "utils/conversations";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { asyncEmptyFn } from "utils/emptyFn";
import { createMockConversation } from "utils/mocks/conversations";
import {
  TwilioConversation,
  TwilioMessage,
  TwilioMessagePaginator,
  ConversationItem,
  ConversationItemCommsEvent,
} from "../types";
import {
  convertCommsEventToConversationItem,
  convertMessageToConversationItem,
} from "../utils";
import { useConversationModal } from "./ConversationModalContext";
import { handlePusherResourceEvent, pusher } from "services/Pusher";
import { agentSelector } from "state/agentSlice";

type CommsEventsParams = Parameters<typeof getTenantCommsEvents>[1];

export interface ConversationContextValue {
  active: boolean;
  conversationReady: boolean;
  isLoadingMessages: boolean;
  isSendingMessage: boolean;
  conversation: Conversation;
  paginator?: TwilioMessagePaginator;
  currentConversationItems: ConversationItem[];
  previousConversationItems: ConversationItem[];
  loadPrevious: () => Promise<void>;
  sendMessage: (message: string) => Promise<void>;
  getAuthorInfo: (author: string | null) => {
    isAgent: boolean;
    displayName: string;
  };
}

const initialValue: ConversationContextValue = {
  active: false,
  conversationReady: false,
  isLoadingMessages: false,
  isSendingMessage: false,
  conversation: createMockConversation(),
  currentConversationItems: [],
  previousConversationItems: [],
  loadPrevious: asyncEmptyFn,
  sendMessage: asyncEmptyFn,
  getAuthorInfo: (author) => ({
    isAgent: false,
    displayName: author ?? "Unknown participant",
  }),
};

const ConversationContext =
  createContext<ConversationContextValue>(initialValue);

export interface ConversationProviderProps {
  children: React.ReactNode;
  conversation: Conversation;
  active: boolean;
}

export const ConversationProvider: React.FC<ConversationProviderProps> = ({
  children,
  conversation,
  active,
}) => {
  const [conversationReady, setConversationReady] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const { messageInputValue, setMessageInputValue, setIsSendingGateCode } =
    useConversationModal();
  const [paginator, setPaginator] = useState<
    TwilioMessagePaginator | undefined
  >();
  // Initial messages and events and incoming messages
  const [currentConversationItems, setCurrentConversationItems] = useState<
    ConversationItem[]
  >([]);
  // Previous messages and events (before initial fetch)
  const [previousConversationItems, setPreviousConversationItems] = useState<
    ConversationItem[]
  >([]);

  const { showErrorSnack, showSuccessSnack } = useSnackbar();
  const agent = useSelector(agentSelector);
  const { clientReady, getConversationBySid, agentIdentityRecord } =
    useTwilioConversations();

  const dispatch = useDispatch();
  const conversationDraft = useSelector(conversationDraftSelector);

  const twilioConversation = useMemo(
    () => getConversationBySid(conversation.twilioConversationSid),
    [conversation.twilioConversationSid, getConversationBySid]
  );

  const getCommsEventConversationItems = async (
    customerId: number,
    commsEventsParams: CommsEventsParams
  ) => {
    const res = await getTenantCommsEvents(customerId, commsEventsParams);
    return res.data.results.map(convertCommsEventToConversationItem);
  };

  useEffect(() => {
    const ready = clientReady && !!twilioConversation;
    setConversationReady(ready);

    // Exit early if client is uninitialized or conversation hasn't be joined.
    if (!ready) {
      return;
    }

    // Fetch initial messages and set up event handlers
    loadInitial(twilioConversation);
    twilioConversation.on("messageAdded", handleMessageAdded);
    return () => {
      twilioConversation.removeAllListeners();
    };
  }, [clientReady, twilioConversation]);

  useEffect(
    function clearConversationOnClose() {
      if (!active) {
        setPaginator(undefined);
        setCurrentConversationItems([]);
        setPreviousConversationItems([]);
        saveConversationDraft();
      }
    },
    [active]
  );

  useEffect(
    function loadDraftMessage() {
      if (
        active &&
        conversationDraft &&
        conversationDraft.id === conversation.id &&
        messageInputValue === ""
      ) {
        setMessageInputValue(conversationDraft.message);
        dispatch(setConversationDraft());
      }
    },
    [active]
  );

  useEffect(
    function subscribeToCommsEventCreated() {
      if (!conversation.customerId) {
        return;
      }
      const orgPusherPrefix = agent.organization?.pushChannelPrefix ?? "";
      const pushChannelPrefix =
        orgPusherPrefix + conversation.customerId.toString();

      const commsEventCreatedChannelName =
        pushChannelPrefix + "CommsEvent_created";

      const commsEventCreatedChannels = pusher.subscribe(
        commsEventCreatedChannelName
      );

      const handleCommsEventCreated = (data: CommsEvent) => {
        const newCommsEvent = convertCommsEventToConversationItem(data);
        setCurrentConversationItems((curr) => [...curr, newCommsEvent]);
        if (
          data.type === CommsEventType.GATE_CODE_SUCCESS ||
          data.type === CommsEventType.GATE_CODE_ERROR
        ) {
          setIsSendingGateCode(false);
          if (data.type === CommsEventType.GATE_CODE_ERROR) {
            showErrorSnack("Facility access information failed to send");
          }
          if (data.type === CommsEventType.GATE_CODE_SUCCESS) {
            showSuccessSnack(
              "Facility access information was sent successfully"
            );
          }
        }
      };

      commsEventCreatedChannels.forEach((commsEventCreatedChannel) => {
        commsEventCreatedChannel.bind("model_created", (event: unknown) =>
          handlePusherResourceEvent(event, handleCommsEventCreated)
        );
      });

      return () => {
        commsEventCreatedChannels.forEach((commsEventCreatedChannel) => {
          commsEventCreatedChannel.unbind("model_created");
        });

        pusher.unsubscribe(commsEventCreatedChannelName);
      };
    },
    [conversation]
  );

  const getConversationItemsFromPaginator = async (
    twilioPaginator: TwilioMessagePaginator,
    commsEventsParams: CommsEventsParams = {}
  ): Promise<ConversationItem[]> => {
    // Get messages from Twilio paginator and map into ConversationItem[]
    const messageItems = twilioPaginator.items.map(
      convertMessageToConversationItem
    );

    // If conversation has a customer attached, fetch CommsEvents
    // with provided params and return inline with messages.
    if (conversation.customer) {
      let commsEventItems: ConversationItemCommsEvent[] = [];
      try {
        // NOTE: currently queries for 100 max events
        commsEventItems = await getCommsEventConversationItems(
          conversation.customer.id,
          commsEventsParams
        );
      } catch (error) {
        const err = error as AxiosError;
        if (err?.response?.status === 413) {
          try {
            // Reduce limit to 50 if 413 Payload Too Large
            commsEventItems = await getCommsEventConversationItems(
              conversation.customer.id,
              { ...commsEventsParams, limit: 50 }
            );
          } catch {
            showErrorSnack("Unable to fetch comms events with reduced limit");
          }
        } else {
          showErrorSnack("Unable to fetch comms events");
        }
      }

      if (commsEventItems.length > 0) {
        return [...messageItems, ...commsEventItems].sort((a, b): number =>
          a.timeCreated <= b.timeCreated ? -1 : 1
        );
      }
    }
    // Otherwise (no attached customer or fetching CommsEvents failed),
    // just return messages.
    return messageItems;
  };

  const loadInitial = async (twilioConversation: TwilioConversation) => {
    try {
      setIsLoadingMessages(true);
      const initialPaginator = await twilioConversation.getMessages();

      // If there are more messages remaining, only fetch CommsEvents
      // in the range of messages received. Otherwise, fetch all remaining
      // CommsEvents for the tenant.
      const commsEventParams: CommsEventsParams = {};
      if (
        initialPaginator.hasPrevPage &&
        initialPaginator.items[0]?.dateCreated
      ) {
        commsEventParams.start =
          initialPaginator.items[0].dateCreated.toISOString();
      }

      const conversationItems = await getConversationItemsFromPaginator(
        initialPaginator,
        commsEventParams
      );

      setCurrentConversationItems(conversationItems);
      setPaginator(initialPaginator);
    } catch (e) {
      showErrorSnack(
        `Could not fetch messages: ${conversation.twilioConversationSid}`
      );
    } finally {
      setIsLoadingMessages(false);
    }
  };

  const loadPrevious = async () => {
    // Typeguard/early exit
    if (!paginator?.hasPrevPage) {
      return;
    }

    try {
      const prevPaginator = await paginator.prevPage();

      // Get end param/upper limit from earliest conversation item
      const earliestConversationItem =
        previousConversationItems[0] ?? currentConversationItems[0];

      // If there are more messages remaining, only fetch CommsEvents
      // in the range of messages received. Otherwise, fetch all remaining
      // CommsEvents for the tenant.
      const commsEventParams: CommsEventsParams = {
        end: earliestConversationItem?.timeCreated,
      };

      if (prevPaginator.hasPrevPage && prevPaginator.items[0].dateCreated) {
        commsEventParams.start =
          prevPaginator.items[0].dateCreated.toISOString();
      }

      // Append new items to start of array
      const conversationItems = await getConversationItemsFromPaginator(
        prevPaginator,
        commsEventParams
      );
      setPreviousConversationItems((curr) => [...conversationItems, ...curr]);
      setPaginator(prevPaginator);
    } catch {
      showErrorSnack("Could not fetch previous messages.");
    }
  };

  const handleMessageAdded = (message: TwilioMessage) => {
    // Append new item to end of array
    setCurrentConversationItems((curr) => [
      ...curr,
      convertMessageToConversationItem(message),
    ]);
  };

  const sendMessage = useCallback(
    async (message: string) => {
      if (twilioConversation) {
        try {
          setIsSendingMessage(true);
          await twilioConversation.sendMessage(message);
          setMessageInputValue("");
        } catch (e) {
          showErrorSnack("An error occurred while sending the message");
        } finally {
          setIsSendingMessage(false);
        }
      } else {
        showErrorSnack("An error occurred while sending the message");
      }
    },
    [twilioConversation]
  );

  const getAuthorInfo = useCallback(
    (author: string | null): { isAgent: boolean; displayName: string } => {
      if (author && agentIdentityRecord[author]) {
        return { isAgent: true, displayName: agentIdentityRecord[author] };
      }

      // Assume author is customer since other
      // participants would be agents.
      return {
        isAgent: false,
        displayName: getConversationCustomerDisplayName(conversation),
      };
    },
    [conversation.customer, agentIdentityRecord]
  );

  const saveConversationDraft = useCallback(() => {
    if (messageInputValue) {
      dispatch(
        setConversationDraft({
          id: conversation.id,
          message: messageInputValue,
        })
      );
    }
  }, [dispatch, setConversationDraft, conversation.id, messageInputValue]);

  return (
    <ConversationContext.Provider
      value={{
        active,
        conversationReady,
        isLoadingMessages,
        isSendingMessage,
        conversation,
        paginator,
        currentConversationItems,
        previousConversationItems,
        loadPrevious,
        sendMessage,
        getAuthorInfo,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export const useConversation = () => useContext(ConversationContext);

export const MockConversationModalProvider = createMockContextProvider(
  ConversationContext,
  initialValue
);
